<template>
  <div>
    <div v-if='isViewMode'>
      {{selectableValue}}
    </div>
    <select v-else
      v-model='selectableValue'
      @change='handleUpdate'
      class='form-select w-64'>
      <option v-for='selectable in field.selectable_values' :key='`dropdown-selectableValue-${field.custom_field_name}-${selectable}`'>
        {{selectable}}
      </option>
    </select>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CustomFieldDropdown',
  props: ['field', 'isViewMode'],
  data () {
    return {
      selectableValue: ''
    }
  },
  watch: {
    'field.value': {
      handler: function (newVal) {
        this.selectableValue = newVal
      },
      immediate: true,
    }
  },
   methods: {
    handleUpdate () {
      let updated = cloneDeep(this.field)
      updated.value = this.selectableValue
      this.$emit('update-value', updated)
    },
  }
}
</script>
